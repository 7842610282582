.button { backface-visibility: hidden; border: none; border-radius: var(--radius-large); color: var(--color-white); display: inline-block; font-size: .75rem; font-weight: 500; line-height: var(--button-height); min-width: 8rem; overflow: hidden; padding: 0 1.5rem; position: relative; text-align: center; text-decoration: none; transition: all .3s var(--transition) .2s; vertical-align: top; white-space: nowrap; z-index: 1; }
.button::before { border-radius: var(--radius-large); bottom: -4px; content: ''; left: -4px; position: absolute; right: -4px; top: -4px; transform: rotateX(-90deg) rotateY(5deg); transition: all .45s var(--transition); z-index: -1; }
.button:active::before, .button:focus::before, .button:hover::before { transform: rotateX(0) rotateY(0); }

/* Color Variants */
.button.base { background: var(--color-base); }
.button.base::before { background: var(--color-red); }

.button.blue { background: var(--color-blue-2); }
.button.blue::before { background: var(--color-green); }

.button.green { background: var(--color-green); }
.button.green::before { background: var(--color-red); }

.button.red { background: var(--color-red); }
.button.red::before { background: var(--color-green); }

.button.border-blue, .button.border-white { background: none; border: 1px solid; line-height: calc(var(--button-height) - 2px); }

.button.border-blue { border-color: var(--color-blue-2); }
.button.border-blue:active, .button.border-blue:focus, .button.border-blue:hover { background: var(--color-blue-2); }
.button.border-blue::before { background: var(--color-blue-2); }

.button.border-white { border-color: var(--color-white); }
.button.border-white:active, .button.border-white:focus, .button.border-white:hover { background: var(--color-blue-2); border-color: var(--color-blue-2); }
.button.border-white::before { background: var(--color-blue-2); }

/* Modifiers */
.button.w-100 { width: 100%; }

.button.bigger { line-height: calc(var(--button-height) * 1.5625); font-size: 1.166em; }
.button.border-blue.bigger, .button.border-white.bigger { line-height: calc((var(--button-height) * 1.5625) - 2px); }

@media only screen and (min-width: 40rem) {
	.button { padding: 0 2.25rem; }
}