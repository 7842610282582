fieldset { border: none; }

label, .label { color: var(--color-base); cursor: pointer; display: block; font-size: .875rem; font-weight: 400; line-height: 1.68; padding-bottom: .2em; }
label::selection, .label::selection { background: transparent; color: inherit; }

input[type="datetime-local"], input[type="email"], input[type="password"], input[type="text"], select, textarea { background: var(--color-white); border: 1px solid var(--color-gray-2); border-radius: var(--radius-large); color: var(--color-base); font-size: .875rem; font-weight: 400; line-height: var(--input-height); outline: none; padding: 0 1.5rem; transition: all .3s var(--transition); width: 100%; }
input[type="datetime-local"], input[type="email"], input[type="password"], input[type="text"] { height: var(--input-height); }
input[type="datetime-local"]:active, input[type="datetime-local"]:focus, input[type="email"]:active, input[type="email"]:focus, input[type="password"]:active, input[type="password"]:focus, input[type="text"]:active, input[type="text"]:focus, textarea:active, textarea:focus { border-color: var(--color-green); }
input::placeholder, textarea::placeholder { color: var(--color-gray-1); }
select { padding: 1.125rem 1.75rem .25rem; }
textarea { height: 6.5rem; line-height: 1.75; overflow: auto; padding-bottom: .75rem; padding-top: .75rem; resize: vertical; }

/* Elements */
.input-container { margin: 0 0 1.5rem 0; position: relative; }

/* Checkbox / Radio */
.check-container input[type="checkbox"], .check-container input[type="radio"] { height: 30px; left: 0; opacity: 0; position: absolute; top: 0; width: 30px; }
.check-container label { font-size: .875rem; padding: .33em 0 0 2.5rem; position: relative; text-align: left; transition: all .3s var(--transition); }
.check-container label:active, .check-container label:focus, .check-container label:hover { color: var(--color-green); }
.check-container label::before { background: var(--color-white); border: 1px solid var(--color-gray-2); content: ''; height: 24px; left: 0; position: absolute; top: 1px; transition: all .3s var(--transition); width: 24px; }
.check-container label:active::before, .check-container label:focus::before { border-color: var(--color-green); }
.check-container input:checked ~ label::after { content: ''; position: absolute; }

.check-container input[type="checkbox"] ~ label::before { border-radius: var(--radius-small); }
.check-container input[type="checkbox"]:checked ~ label::after { border-color: var(--color-green); border-style: solid; border-width: 0 2px 2px 0; height: 12px; left: 9px; top: 5px; transform: rotateZ(45deg); width: 6px; }

.check-container input[type="radio"] ~ label::before { border-radius: 50%; }
.check-container input[type="radio"]:checked ~ label::after { background: var(--color-green); border-radius: 50%; height: 12px; left: 7px; top: 8px; width: 12px; }

@media only screen and (min-width: 40rem) {
	.check-container label { padding-top: .2em; }
}

/* Dropdown */
.dropdown { position: relative; }
.dropdown select { display: none; }

.dropdown__selection { background: var(--color-white); border: 1px solid var(--color-gray-2); border-radius: var(--radius-large); cursor: pointer; font-size: .875rem; font-weight: 400; line-height: var(--input-height); padding: 0 2.25rem 0 1.5rem; position: relative; transition: all .3s var(--transition); white-space: nowrap; }
.dropdown__selection.active { border-color: var(--color-green); }
.dropdown__selection::before { background: transparent; border-bottom: 1.5px solid var(--color-base); border-left: 1.5px solid var(--color-base); content: ''; height: 8px; pointer-events: none; position: absolute; right: 1rem; top: calc(50% - 7px); transform: rotateZ(-45deg); transition: all .3s var(--transition); width: 8px; z-index: 2; }
.dropdown__selection.active::before { top: calc(50% - 1px); transform: rotateX(180deg) rotateZ(-45deg); }

.dropdown__options { background: var(--color-blue-3); border: 1px solid var(--color-blue-1); border-radius: var(--radius-small); color: var(--color-white); font-size: .875em; left: 0; line-height: 1.7; overflow: hidden; position: absolute; right: 0; top: 100%; z-index: 99; }
.dropdown__options div { cursor: pointer; padding: .5rem 1rem; user-select: none; }
.dropdown__options div + div { border-top: 1px solid rgba(var(--color-rgb-white),.2); }
.dropdown__options div:active, .dropdown__options div:focus, .dropdown__options div:hover, .dropdown__options div.selected { background: var(--color-green); }