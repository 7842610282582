.login { align-items: center; display: flex; flex-direction: column; justify-content: center; min-height: 100vh; padding: 2rem; text-align: center; }

.login__logo { margin: auto auto 3rem; text-align: center; }
.login__logo img { aspect-ratio: 166 / 96; height: 6rem; vertical-align: top; }

.login__form { margin: 0 auto 2.25rem; max-width: 32.5rem; width: 100%; }
.login__form label { color: var(--color-white); font-size: 1rem; text-align: left; }
.login__form input[type="email"], .login__form input[type="password"], .login__form input[type="text"] { border-radius: var(--radius-medium); height: 3rem; line-height: 3rem; }
.login__form .button { border-radius: var(--radius-medium); font-size: 1.125rem; font-weight: 300; line-height: 3rem; text-transform: uppercase; width: 100%; }

.login__container { margin-bottom: .5rem; }
.login__container:last-of-type { margin-bottom: 1rem; }

.login__forgotten { margin-bottom: 3rem; }
.login__forgotten button { background: transparent; border: none; color: rgba(var(--color-rgb-white),.7); font-size: 1rem; text-decoration: underline; transition: all .3s var(--transition); }
.login__forgotten button:active, .login__forgotten button:focus, .login__forgotten button:hover { color: rgba(var(--color-rgb-white),.8); text-decoration: none; }

.login__footer { font-size: .675rem; margin-top: auto; }
.login__footer a { color: var(--color-white); font-weight: 500; text-decoration: none; }
.login__footer a:active, .login__footer a:focus, .login__footer a:hover { text-decoration: underline; text-decoration-thickness: 1px; text-underline-offset: .1em; }