.app { min-height: 100vh; overflow: hidden; z-index: 1; }

.app__main { padding: 0 1.25rem; }

@media only screen and (min-width: 80rem) {
	.app { display: flex; justify-content: space-between; }

	.side { width: 18rem; }

	.app__main { padding: 2.5rem 3rem 2.75rem 0; width: calc(100% - 20.875rem); }
}