* { box-sizing: border-box; margin: 0; padding: 0; }

@font-face { font-display: swap; font-family: 'Inter'; src: url('../../font/Inter-Regular.woff2') format('woff2'); font-weight: 400; }
@font-face { font-display: swap; font-family: 'Inter'; src: url('../../font/Inter-Medium.woff2') format('woff2'); font-weight: 500; }
@font-face { font-display: swap; font-family: 'Inter'; src: url('../../font/Inter-Light.woff2') format('woff2'); font-weight: 300; }

:root {
	--color-base: #27272a;
	--color-black: #000;
	--color-rgb-black: 0,0,0;
	--color-blue-1: #131c55;
	--color-rgb-blue-1: 19,28,85;
	--color-blue-2: #4256d0;
	--color-blue-3: #4658ac;
	--color-gray-1: #a1a1aa;
	--color-gray-2: #d4d4d8;
	--color-gray-3: #f4f4f5;
	--color-green: #23aa4a;
	--color-red: #dc2626;
	--color-white: #fff;
	--color-rgb-white: 255,255,255;

	--button-height: 2rem;

	--font-main: 'Inter', Verdana, Arial;

	--input-height: var(--button-height);

	--radius-small: .1875rem;
	--radius-medium: .75rem;
	--radius-large: 3.125rem;

	--page-width-max: 128rem;

	--sprite: url(../../img/sprite.svg) no-repeat;

	--transition: ease-out;

	accent-color: var(--color-blue-1);
	font-size: 14px;
}

::selection { background: var(--color-green); color: var(--color-white); }

article, aside, figure, footer, header, main, nav, section { display: block; }

img, video { height: auto; max-width: 100%; }

@media only screen and (min-width: 40rem) {
	:root {
		font-size: 16px;
	}
}