.mb-1 { margin-bottom: 1em; }
.mb-2 { margin-bottom: 2em; }
.mb-3 { margin-bottom: 3em; }
.mb-4 { margin-bottom: 4em; }
.mb-5 { margin-bottom: 5em; }

.mt-1 { margin-top: 1em; }
.mt-2 { margin-top: 2em; }
.mt-3 { margin-top: 3em; }
.mt-4 { margin-top: 4em; }
.mt-5 { margin-top: 5em; }

.pa-1 { padding: 1em; }
.pa-2 { padding: 2em; }
.pa-3 { padding: 3em; }
.pa-4 { padding: 4em; }

.text-big { font-size: 1.33em; }
.text-bigger { font-size: 1.66em; }
.text-blue { color: var(--color-blue-2); }
.text-bold { font-weight: 500; }
.text-center { text-align: center; }
.text-green { color: var(--color-green); }
.text-left { text-align: left; }
.text-light { font-weight: 300; }
.text-red { color: var(--color-red); }
.text-right { text-align: right; }
.text-upp { text-transform: uppercase; }
.text-white { color: var(--color-white); }
.text-white-50 { color: rgba(var(--color-rgb-white),.5); }
.text-wrap { white-space: normal; }

[hidden], .hide { display: none; }