.app__table { overflow-x: auto; padding: 1.5rem 0 3rem; white-space: nowrap; }
.app__table::-webkit-scrollbar { width: .9em; }
.app__table::-webkit-scrollbar-thumb { background: var(--color-blue-3); border-radius: var(--radius-medium); }
.app__table::-webkit-scrollbar-track { border-radius: var(--radius-medium); box-shadow: inset 0 0 5px rgba(var(--color-rgb-black),.25); }
.app__table td, .app__table th { padding: 1em; }
.app__table td { border-bottom: 1px solid var(--color-gray-3); }
.app__table td:first-child, .app__table th:first-child { padding-left: 2.5rem; }
.app__table td:last-child, .app__table th:last-child { padding-right: 2.5rem; }

/* Input + Button */
.app__table__input { display: flex; }
.app__table__input input { margin-right: .5em; width: auto; }

/* Quantity */
.app__table__qty { align-items: center; display: flex; justify-content: space-between; }