.My-form-error {
    margin-top: 1vh;
    margin-bottom: 2vh;
    width: 100%;
    text-align: center;
    color: red;
    font-weight: bold;
}

.My-form-success {
    margin-top: 1vh;
    margin-bottom: 2vh;
    width: 100%;
    text-align: center;
    color: green;
    font-weight: bold;
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open{
    opacity: 1;
}

.ReactModal__Overlay--before-close{
    opacity: 0;
}

@font-face {
    font-family: 'password';
    font-style: normal;
    font-weight: 600;
    src: url(https://jsbin-user-assets.s3.amazonaws.com/rafaelcastrocouto/password.ttf);
}
.makeTextHidden {
    font-family: 'password';
}

.My-form-tooltip {
    margin-top: 1vh;
    text-align: center;
    font-style: italic;
    color: #4d4d4d;
}

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
    visibility: hidden;
    white-space: normal;
    max-width:400px;
    word-wrap: break-word;
    background-color: var(--color-blue-3);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    top: 150%;
    left: 50%;
    margin-left: -200px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}



