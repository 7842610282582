.side { background: linear-gradient(180deg, var(--color-blue-1) 0%, rgba(var(--color-rgb-blue-1),.35) 105%, rgba(var(--color-rgb-blue-1),0) 170%); color: var(--color-white); margin-bottom: 1.5rem; padding: 2rem 1.75rem 2.25rem; position: relative; }
.side::before { background: var(--color-blue-3); bottom: 0; content: ''; left: 0; position: absolute; right: 0; top: 0; z-index: -1; }

/* Logo */
.side__logo { margin-bottom: 2.5rem; text-align: center; }
.side__logo img { height: 3rem; vertical-align: top; }

/* "Navigation" */
.side__nav { margin-bottom: 2.7rem; }
.side__nav ul { list-style: none; margin: 0; }
.side__nav li + li { margin-top: .675rem; }

/* Box */
.side__box { font-size: .75rem; position: relative; }
.side__box:not(:last-child) { margin-bottom: 1.5rem; }
.side__box header { border-bottom: 1px solid rgba(var(--color-rgb-white),.2); cursor: pointer; font-size: 1rem; line-height: 1.2; margin-bottom: 1.25rem; padding-bottom: .5em; }
.side__box section { height: 0; overflow: hidden; transition: all .3s var(--transition); }
.side__box section.active { height: auto; }
.side__box p:not(:last-child) { margin-bottom: .66em; }

/* Box with icon */
.side__box[class$="-ico"] header { padding-right: 2rem; position: relative; }
.side__box[class$="-ico"] header::before { background: var(--sprite); background-size: 800px 800px; content: ''; height: 20px; position: absolute; right: 0; top: calc(50% - (10px + .25em)); width: 20px; }
.side__box.file-ico header::before { background-position: -40px -40px; }
.side__box.sort-ico header::before { background-position: -140px -40px; }
.side__box.cog-ico header::before { background-position: -240px -40px; }
.side__box.exp-ico header::before { background-position: -340px -40px; }
.side__box.stat-ico header::before { background-position: -440px -40px; }
.side__box.user-ico header::before { background-position: -540px -40px; }

/* Switch */
.side__switch { --switch-height: 1.875rem; --switch-space: .35rem; --switch-width: 4.25rem; position: relative; }
.side__switch input[type="checkbox"] { left: 14px; opacity: 0; position: absolute; top: 9px; }
.side__switch label { color: var(--color-white); display: inline-block; height: var(--switch-height); position: relative; text-indent: -9999em; width: var(--switch-width); }
.side__switch label::before { border: 1px solid var(--color-white); border-radius: var(--radius-large); content: ''; height: calc(100% - 2px); left: 0; position: absolute; top: 0; width: calc(100% - 2px); }
.side__switch label::after { background: var(--color-gray-1); border-radius: 50%; content: ''; height: calc(var(--switch-height) - (2px + var(--switch-space))); left: var(--switch-space); position: absolute; top: calc(var(--switch-space) - 1.25px); transition: all .4s var(--transition); width: calc(var(--switch-height) - (2px + var(--switch-space))); }
.side__switch input:checked ~ label::after { background: var(--color-green); left: calc(100% - (var(--switch-height) - var(--switch-space) + 2px)); }

/* Limiter */
.side__limiter { display: flex; flex-wrap: wrap; gap: .33em; list-style: none; margin: 0; }
.side__limiter .button { background: none; border: 1px solid var(--color-white); border-radius: 50%; color: var(--color-white); font-size: .75rem; height: 2rem; line-height: 1.8rem; min-width: 0; padding: 0; width: 2rem; }
.side__limiter .button:active, .side__limiter .button:focus, .side__limiter .button:hover, .side__limiter .button.active { background: var(--color-green); border-color: var(--color-green); }
.side__limiter .button::before, .side__limiter .button.active::before { background: var(--color-green); }

/* Stats */
.side__stats { align-items: center; display: flex; justify-content: space-between; }
.side__stats + .side__stats { margin-top: .75em; }
.side__stats span:last-child { padding-left: 1em; text-align: right; }

@media only screen and (min-width: 40rem) {
	.side { display: flex; flex-wrap: wrap; justify-content: space-between; }

	.side__logo, .side__nav { width: 100%; }

	.side__box { width: 48.5%; }
}

@media only screen and (min-width: 60rem) {
	.side__box { width: 32%; }
}

@media only screen and (min-width: 80rem) {
	.side { display: block; margin-bottom: 0; padding: 2.4rem 2rem 3.5rem; }

	.side__box { width: 100%; }
	.side__box:not(:last-child) { margin-bottom: 2.75rem; }

	.side__box header { cursor: default; }
	.side__box section { height: auto; overflow: visible; }
}